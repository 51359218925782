.dropdownToggleAfter::after {
  display: none;
  border: none;
}

.dropdownToggleAfter {
  background-color: transparent !important;
  border: none;
  outline: none !important;
}

.addUserButtonRow {
  display: flex;
  justify-content: space-between;
  padding-right: 0px;
  padding-left: auto;
}

.addRoleButton {
  background-color: #6583E8;
  outline: none;
  border: none;
  padding: 10px 10px;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  line-height: 1;
}

.saveButton {
  padding: 10px 23px;
  background-color: #6583E8;
  border: none;
  outline: none;
  color: white;
  font-size: 14px;
  font-weight: 700;
  border-radius: 5px;
}

.cancelButton {
  padding: 10px 23px;
  background-color: white;
  color: black;
  outline: none;
  border: 1px solid #b3b3b3;
  font-size: 14px;
  font-weight: 400;
  border-radius: 5px;
}
