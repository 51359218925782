html {
    scroll-behavior: smooth;
  }
  body{
      font-family: "Exo", sans-serif;
      font-weight: 400;
      color: #0a1728;
      line-height: 1.5;
  }
  a{
      text-decoration: none;
      color: inherit;
  }
  header{
  /*	margin-bottom: 15px;*/
  }
  .main_section_title{
      font-size: 66px !important;
  }
  .text-primary{
      color: #1253A4 !important;
  }
  .fs-14{
      font-size: 14px;
  }
  .fs-16{
      font-size: 16px;
  }
  .fs-18{
      font-size: 18px;
  }
  .fs-24{
      font-size: 24px;
  }
  .card-indigo{
      background: rgba(179, 88, 254, 0.31);
  }
  .card-orange{
      background: #FFC135;
  }
  .card-light-blue{
      background: #C8DCDC;
  }
  .card{
      border:transparent;
      border-radius: 20px;
  }
  .card:hover{
      transition: all .3s linear;
  }
  .btn-primary{
      background: #1253A4;
      border-color: #1253A4;
  }
  .btn-outline-primary{
      border-color: #11243F;
      color: #11243F;
  }
  .navbar-nav .nav-item {
      margin-left: 10px;
  }
  .navbar-nav .nav-item .nav-link{
      color: inherit;
  }
  .navbar-nav .nav-item .nav-link:hover,
  .navbar-nav .nav-item .nav-link.active{
      color: #1253a4;
  }
  .navbar-nav .nav-item .nav-link.active{
      font-weight: 700;
  }
  .top_section{
      background: url('../images/images-landing/main_bg.png') no-repeat;
      background-size: cover;
  }
  .inner_wrap{
      max-width: 520px;
  }
  .banner_title {
      font-size: 40px;
      font-weight: 700;
      margin-bottom: 30px;
  }
  .accordion-button:not(.collapsed),
  .accordion-button.collapsed{
      background-color: unset;
      box-shadow: none;
      color: #000;
  }
  .banner-info {
      margin-bottom: 30px;
  }
  .btn {
      height: 48px;
      line-height: 2.25;
  }
  .card-title{
      font-weight: 700;
      font-size: 46px;
      line-height: 1.25;
  }
  .left_image {
      max-width: 520px;
      margin: 0 auto;
  }
  .bg-gray {
      background: #EFF2F7;
  }
  .bg-primary{
      background: #1252A0 !important	;
      color: #fff;
  }
  .border-top-radius{
      border-radius: 20px 20px 0 0;
  }
  .section_title, ul li .amount{
      font-size: 45px;
      font-weight: 700;
      line-height: 1.25;
  }
  .section_info{
      color: #11243F;
  }
  .my_list_1 li{
      padding: 10px;
      width: calc(50% - .5rem);
      line-height: 1.2;
      border-right: 1px solid #014638;
  }
  .my_list_1 li:last-child{
      border-right: 1px solid transparent;	
  }
  .partners {
      background: #11243F;
      color: #fff;
      padding: 40px 0;
      text-align: center;
  }
  .partners .logos {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
  }
  .pay_part{
      font-size: 28px;
  }
  .bene_card{
      border-radius: 30px;
      box-shadow: 0 7px 24px 0 rgba(159, 143, 237, 0.2);
      border-color: transparent;
      /* opacity: 0;
        transform: translateX(-100px);  */
        transition: opacity 0.6s ease-out, transform 0.6s ease-out;
        transition-delay: 0.6s; 
  }
  .animate_this{
      /* opacity: 0;
        transform: translateX(-100px);  */
        transition: opacity 0.6s ease-out, transform 0.6s ease-out;
        transition-delay: 0.6s; 
  }
  .z-indexSec{
    z-index: 1;
  }
  .Priceplan_Font{
    font-size: 16px;
  }
  .commingFont{
    font-size: 36px;
  }
  .btn:hover{
      transition: all .2s linear;
  
  }
  .bene_card:hover{
      background: #dee2e6;
      transition: all .3s linear;
  }
  .bene_icon {
      box-shadow: -3px 15px 35px 0 rgba(0, 0, 0, 0.08);
      border-radius: 50%;
      width: 80px;
      height: 80px;
      padding: 17px 0;
      text-align: center;
      background: #fff;
  }
  .benititle,.feature_box_title{
      font-size: 24px;
      font-weight: 700;
      margin: 20px 0 7px;
  }
  section,
  .section {
    /* opacity: 0 !important; */
    /* transform: translateY(100px); */
    transition: opacity .8s ease-out, transform .8s ease-out;
    position: relative;
  }
  
  .feature_card{
      border-radius: 18px;
      border:6px solid #F3F3F3;
      /* opacity: 0;
        transform: translateY(-100px);  */
        transition: opacity 0.6s ease-out, transform 0.6s ease-out;
        transition-delay: 0.6s; 
  }
  .feature_card:hover{
      background: #1253A4;
      color: #fff;
  }
  .section.visible, .section.visible .card, .section.visible img,
  section.visible, section.visible .card,section.visible img{
    opacity: 1 !important;
    transform: translateY(0);
  }
  .animation{
      opacity: 0;
        transform: translateY(-100px); 
        transition: opacity 0.6s linear, transform 0.6s linear;
        transition-delay: 0.6s; 
  }
  .visible .animation{
      opacity: 1;
      transform: translateY(0);
  }
  .about_us {
      background: url('../images/images-landing/about_us_bg.png') no-repeat;
      background-size: cover;
      padding-block: 45px;
      background-position: bottom;
  }
  .about_title {
      font-size: 50px;
      font-weight: 700;
  }
  .rep_list li{
      margin-bottom: 15px;
  }
  .rep_list .card{
      box-shadow: 0 14px 24px 0 rgba(26, 104, 255, 0.05);
      border-radius: 14px;
      border:transparent;
      padding-inline: 15px;
  }
  .rep_list .card:hover{
      box-shadow: 0 14px 24px 0 rgba(159, 143, 237, 0.2);
  }
  .rep_list .rep_info{font-weight: 700;font-size: 20px;max-width: 290px}
  
  
  .price_plan  {
      background: url('../images/images-landing/price_plan.png') no-repeat;
      background-size: cover;
      padding-block: 45px;
      background-position: bottom;
      margin-top:-160px;
      padding-top: 240px;
  }
  .testimonials{
      padding:75px 0 25px;
  }
  .testimonials .card{
      background: transparent url('../images/images-landing/testimonailbg-active.png') no-repeat;
      background-size: contain;
      background-position: center;
  }
  .testimonials .owl-item.active:first-child .card{
      background: transparent url('../images/images-landing/testimonial_active1.png') no-repeat;
      background-size: contain;
      background-position: center;
  }
  .testimonials .owl-item.active:last-child  .card{
      background: transparent url('../images/images-landing/testimonial_active2.png') no-repeat;
      background-size: contain;
      background-position: center;
  }
  .testimonials .card-body{
      background: transparent;
  }
  .testimonials .profile img{
      height: 80px;
      width: 80px !important;
      border-radius: 50%;
      margin: 0 auto;
  }
  .testimonials svg{
      margin:15px 0;
  }
  .testimonials .profile p{
      font-size: 20px;
      font-weight:700;
      margin:10px 0;
  }
  .testimonials .message_comment {
      padding: 0px 45px 30px;
      font-size: 14px;
      max-width: 350px;
      height: 280px;
      margin: 0 auto;
  }
  .testimonials .number_here{
      background: #1253A4;
      width: 132px;
      height: 132px;
      line-height: 2;
      font-size: 48px;
      font-weight: 700;
      color: #fff;
      text-align: center;
      border-radius: 50%;
      border:20px solid #D9D9D9;
      margin: 0 auto 45px;
  }
  .testimonials .how_to_pay{
      font-size: 18px;
      font-weight: 600;
  }
  .testimonials .top_arrows {
      position: absolute;
      top: 60px;
      right: -75px;
       width: 100%;
       max-width: 150px; 
  }
  .testimonials .top_arrows2 {
      transform: rotateX(180deg);
  }
  
  .comming_soon{
      background: transparent url('../images/images-landing/comming_soon.png') no-repeat;
      background-size: cover;
      min-height: 400px;
      margin-top: 200px;
  }
  .comming_soon .content_new{
      max-width: 260px;
  }
  .iphone-image{
      position: absolute;
      right: 0;
      bottom: 15px;
      max-width: 600px;
  }
  .accordion-button:not(.collapsed) {
      background-color: transparent !important;
      box-shadow: none !important;
  }
  
  .accordion-button::after{
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3C!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--%3E%3Cpath d='M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 144L48 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l144 0 0 144c0 17.7 14.3 32 32 32s32-14.3 32-32l0-144 144 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-144 0 0-144z'/%3E%3C/svg%3E");
  }
  .accordion-button:not(.collapsed)::after{
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3C!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--%3E%3Cpath d='M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z'/%3E%3C/svg%3E");
  }
  
  
  .security{
      background: #F8F8F8;
  }
  .security .card{
      box-shadow: -3px 15px 35px 0 rgba(0, 0, 0, 0.08);
      border-radius: 40px;
      border:0px;
  }
  .security .card svg{
      fill: #1253A4;
  }
  .security .card:hover svg{
      fill: #fff;
  }
  
  .post-card{
      background-color: #F5F5F5;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0);
      border-radius: 10px;
      border:1px solid #F5F5F5;
      font-size: 14px;
      height: 100%;
      /* opacity: 0;
        transform: translateX(-100px);  */
        transition: opacity 0.6s linear, transform 0.6s linear;
        transition-delay: 0.6s; 
  }
  .post-card:hover{
      background-color: #ddd;
  }
  .visible .post-card{
      opacity: 1;
      transform: translateX(0); 
  }
  .post-card .entry-title{
      font-size: 20px;
      font-weight: 700;
  }
  .follow_us_main{
      background: url("../images/images-landing/footer_bg.png") no-repeat;
      background-size: cover;
      padding:75px 45px;
  }
  
  .follow_us i{
      color: #1253A4;
  }
  .follow_us_main ul li a{
      display: block;
      width: 42px;
      height: 42px;
      border-radius: 5px;
      background: #fff;
      text-align: center;
      line-height: 1.75;
      font-size: 24px;
  }
  .subscribe .form-control{
      height: 52px;
      border-radius: 20px;
  }
  .btn-subscribe{
      border-radius: 20px;
      line-height: 2;
      font-weight: 600;
      text-align: center;
      color: #fff;
      padding: 9px 20px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 1px;
  }
  footer .footer_text{
      font-size: 14px;
      margin-bottom: 30px	;
  }
  footer .footer_title{
      color: #11243F;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 15px;
      padding-left: 25px;
  }
  footer .footer_links{
      padding-left:25px;
  }
  footer .footer_links li{
      margin-bottom: 10px;
  }
  footer .border-right-custom{
      border-right:  1px solid #C7DCDC;
  }
  footer a{
      color: #6D6D6D;
      font-weight: 400;
  }
  .contact_list li {
      display: flex;
      gap: 15px;
  }
  .contact_list .footer_list_icon{
      font-size: 18px;
  }
  .bg-financial{
      background: linear-gradient(360deg, #353535 0%, #1253a4 100%);
      border-radius: 30px;
  }
  .contact_us input.form-control{
      height: 48px;
      padding-left: 20px;
  }

  .contact_us_form input.form-control{
    height: 48px;
    padding-left: 20px;
    border-radius: 4px;
    border:#9E9E9E 1px;
}
.contact_us_form_input{
    height: 40px;
    font-weight: 400;
    padding-left: 20px;
    border-radius: 12px;
    color:#9E9E9E !important;
    border: 1px solid #9E9E9E;
}
.contact_us_button{
    height: 30px;
    font-weight: 400;
    color: white;
    background-color: #1253A4;
    border: 1px solid #1253A4;
    border-radius: 12px;
}
  
  
  /*Custome Login*/
  .custom_modal{
      transition: all .5s linear;
      position: fixed;
      inset: 0;
      z-index: 9999;
  }
  .login_wrapper {
      border-radius: 15px;
      box-shadow: 0px 15px 20px rgba(0,0,0,0.1);
      
  }
  .register_wrapper{
      max-width: 750px;
      width: 100%;
  }
  .custom_modal:before{
      content: '';
      position: absolute;
      background: rgba(0, 0, 0, .5);
      inset: 0;
  }
  .popup_close{
      position: absolute;
      right: 15px;
      top: 15px;
      color: #fff;
      opacity: 0.8;
  }
  .popup_close:hover,
  .popup_close:focus{
      color: #fff;
      opacity: 1;
  
  }
  .login_wrapper .login_title{
    font-size: 35px;
    font-weight: 600;
    text-align: center;
    line-height: 100px;
    color: #fff;
    user-select: none;
    border-radius: 0.2rem 0.2rem 0 0;
  /*  background: linear-gradient(-135deg, #c850c0, #4158d0);;*/
    background: #6583E8;
  }
  .login_wrapper form{
    padding: 10px 30px 50px 30px;
  }
  .login_wrapper form .field{
    position: relative;
  }
  
  .login_wrapper form .form-control,
  .login_wrapper form .form-select{
    height: 50px;
    outline: none;
    font-size: 17px;
    padding-left: 20px;
    border: 1px solid lightgrey;
    border-radius: 25px;
    transition: all 0.3s ease;
  }
  .login_wrapper form .field input:focus,
  form .field input:valid{
    border-color: #4158d0;
  }
  .login_wrapper form .field label{
    position: absolute;
    top: 50%;
    left: 20px;
    color: #999999;
    font-weight: 400;
    font-size: 17px;
    pointer-events: none;
    transform: translateY(-50%);
    transition: all 0.3s ease;
  }
  .register_wrapper form .field label{
      left: 30px;
  }
  form .field input:focus ~ label, form .field input:valid ~ label {
      top: 0%;
      font-size: 13px;
      color: #4158d0;
      background: #fff;
      transform: translateY(-50%);
      padding: 5px;
  
  }
  form .content{
    display: flex;
    width: 100%;
    height: 50px;
    font-size: 16px;
    align-items: center;
    justify-content: space-around;
  }
  form .content .checkbox{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  form .content input{
    width: 15px;
    height: 15px;
    background: red;
  }
  form .content label{
    color: #262626;
    user-select: none;
    padding-left: 5px;
  }
  form .field .btn_login {
      color: #fff;
      border: none;
      padding-left: 0;
      margin-top: -10px;
      font-size: 20px;
      font-weight: 500;
      cursor: pointer;
  /*    background: linear-gradient(-135deg, #c850c0, #4158d0);*/
      background: #6583E8;
      transition: all 0.3s ease;
      width: 100%;
      padding: 10px;
      border-radius: 25px;
  }
  form .field .btn_login:active{
    transform: scale(0.95);
  }
  form .signup-link{
    color: #262626;
    margin-top: 20px;
    text-align: center;
  }
  form .pass-link a,
  form .signup-link a{
    color: #555;
    text-decoration: none;
  }
  form .pass-link a:hover,
  form .signup-link a:hover{
    color: #4158d0;
  }
  .accordion-button{
      padding-block: 12px;
      height: auto;
      position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    /* padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x); */
    font-size: 1rem;
    color: var(--bs-accordion-btn-color);
    text-align: left;
    background-color: var(--bs-accordion-btn-bg);
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: var(--bs-accordion-transition);
    font-weight: 700;
  }
  .accordion-button::after {
    background-image: url(data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3C!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--%3E%3Cpath d='M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 144L48 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l144 0 0 144c0 17.7 14.3 32 32 32s32-14.3 32-32l0-144 144 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-144 0 0-144z'/%3E%3C/svg%3E);
}
.accordion-button::after {
    flex-shrink: 0;
    width: var(--bs-accordion-btn-icon-width);
    height: var(--bs-accordion-btn-icon-width);
    margin-left: auto;
    content: "";
    background-image: var(--bs-accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--bs-accordion-btn-icon-width);
    transition: var(--bs-accordion-btn-icon-transition);
}
.navbar-toggler{
    background: #6583E8;
}
.barsIcon{
    color: #fff;
    font-size: 30px;
}
.iconEye{
    top: 11px;
    right: 48px;
}
.iconEye .absolute{
    color: #1252A0;
}
/* captcha */
.rc-anchor-dark{
    background: #fff !important;
    color: #111 !important;
    border: 1px solid #6583E8 !important;
}
  @media (min-width: 1400px) {
    .container,.container-lg,.container-md,.container-sm,.container-xl,.container-xxl {
        max-width:1320px !important;
    }
}
  @media screen and (max-width:1028px){
      .price_plan{
          margin-top: 0;
          padding-block: 50px;
      }
  }
  @media screen and (max-width:768px){
      .btn{margin-bottom: 15px;}
      .banner_title {font-size: 30px;}
      .section_title, ul li .amount {font-size: 38px;}
      .my_list_1 li {width: 100%; display: block !important}
      .benititle, .feature_box_title {font-size: 20px;}
      section{padding-inline: 20px}
      
      .post-header{width: 100%}
      .post-header a{display: block;}
      .post-card img{width: 100%	;}
      .post-body{padding: 15px;}
      .follow_us_main {
          padding: 50px 15px;
          text-align: center;
      }
      .rep_list .rep_info {font-size: 17px;}
      .testimonials .top_arrows {
          right: -44px;
          max-width: 70px;
      }
      .testimonials .number_here{
          margin-bottom: 15px;
      }
      .comming_soon{margin-top: 0;}
      .price_plan{margin-top: 0; padding-top:45px}
      footer .border-right-custom:nth-child(even){
          border-right:0px;
      }
  
  }
  
  @media screen and (max-width:480px){
      .register_wrapper{
          height: 100%;
          overflow-y: auto;
      }
      .section_title,.main_section_title{font-size: 28px !important;}
  /*	.price_plan{padding-top: 140px;}*/
      .testimonials .how_to_pay{margin-bottom:30px}
      .post-card{
          flex-wrap: wrap;
          flex-direction: column;
      }
      .follow_us_main ul li a {
          width: 52px;
          height: 52px;
          line-height: 2;
          margin-bottom: 10px;
      }
      .btn-subscribe{
          position: unset;
          transform: translateY(0);
          width: 100%;
          margin-top: 15px;
      }
  
      .figure{
          width: 100%;
          margin-bottom: 15px;
      }
      footer{
          text-align: center;
      }
      footer .border-right-custom{
          border-right:0px;
          border-bottom:1px solid #C7DCDC;
      }
       .w-75{
           width: 100% !important;
       }
       footer .footer_links {
          padding-left: 0;
          margin: 0 auto;
          width: 216px;
      }
      .card_inner_text{text-align: center;}
      .owl-nav {
          text-align: center;
          font-size: 27px;
          top: 50%;
          position: absolute;
          transform: translateY(-50%);
          display: flex;
          width: 100%;
          justify-content: space-between;
      }
  }