/*!
 * Start Bootstrap - Simple Sidebar (https://startbootstrap.com/templates/simple-sidebar)
 * Copyright 2013-2020 Start Bootstrap
 * Licensed under MIT (https://github.com/StartBootstrap/startbootstrap-simple-sidebar/blob/master/LICENSE)
 */
body{
  background: #f9f9f9 !important;
}
.wrapper {
  overflow-x: hidden;
}
.dropdown-toggle::after{
  display: none;
}
.nav-link .fa-angle-down {
  transition: transform 0.3s ease-in-out; 
  transform: rotate(-180deg);
}
/* .dropdown-menu-right.show .fa-angle-down {
  transform: rotate(90deg); 
} */
.dropdown.show .fa-angle-down {
  transform: rotate(0deg) !important;
}


.filterButton {
  padding: 10px;
  border-radius: 4px;
  /* background: #7d37de; */
  background: #738AD6;
  color: #fff !important;
  font-size: 14px;
  border: none;
  /* font-weight: 600; */
}

.diableResetButton {
    padding: 10px;
    border-radius: 4px;
    background: #ffffff;
    color: #000000 !important;
    font-size: 14px;
    border: 1px solid #0000004D;
}
.sidebar-wrapper {
  min-height: 100vh;
  margin-left: -15rem;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
  width: 240px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  background: #56419e;
}

.sidebar-heading {
  padding: 18px 20px;
  text-align: center;
}
.sidebar-heading a {
  display: inline-block;
}
.sidebar-heading img {
  display: inline-block;
}

.page-content-wrapper {
  min-width: 100vw;
}

.wrapper.toggled .sidebar-wrapper {
  margin-left: 0;
}
.left-bar-main-collapse {
  background: #56419e;
}
.left-bar-submenu {
  display: block;
  background-color: #56419e;
  color: white;
  padding: 10px 10px;
  margin-left: 50px;
  border-left: 2px solid #7261ae;
  font-size: 14px;
}
.left-bar-submenu:hover {
  color: #738bd6;
  text-decoration: none;
  background-color: #6754A8;
}
.trapezoid {
  position: absolute;
  top: 10px;
  left: -18px;
  text-decoration: none;
  z-index: 1;
  padding: 3px 10px;
  cursor: pointer;
  color: #fff !important;
  border-radius: 50%;
  background-color: #56419e;
  border: 5px solid white;
}
.trapezoid:hover {
  color: #fff;
}
.list-group-item-action-main {
  color: #fff !important;
  font-size: 16px;
  padding: 15px 20px;
  display: block;
  font-weight: 300;
  border-bottom: 1px solid #56419f;
}
.list-group-item-action-main:hover {
  color: #fff;
  text-decoration: none;
}

.list-group-item-action-main i {
  float: right;
  margin-top: 5px;
  transition: all 150ms ease-in;
  position: relative;
  top: -3px;
}
.list-group-item-action-main.active {
  background-color: #6754A8 !important;
  color: #fff;
}
.left-bar-submenu.active {
  background-color: #6754A8 !important;
  color: #fff;
}

.list-group-item-action-main[data-toggle="collapse"][aria-expanded="true"] i,
.dropdown.open i {
  /* filter: progid: DXImageTransform.Microsoft.BasicImage(rotation=2); */
  /* transform: rotate(180deg); */
  transition: all 150ms ease-in;
}
.nav-bg {
  background: #fff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  left: 240px;
  padding: 4px 15px !important;
}
.dashboard-header {
  color: black !important;
  font-weight: 600;
  font-size: 17px;
  text-transform: uppercase;
  padding-left: 30px;
  padding-top: 4px;
  /* padding-bottom: 10px; */
}
.navbar-nav {
  margin-left: auto;
}
.nav-item .nav-link {
  color: black !important;
}
.dropdown-menu-right {
  background: #fff;
  padding: 0;
  width: 213px;
  /* text-align: center; */
}
.dropdown-menu-right > .list-group-item-action-main {
  color: #111 !important;
  font-size: 16px;
  font-weight: 400;
  border-bottom: 1px solid #ccc;
}
.dropdown-menu-right > .list-group-item-action-main:nth-child(2){
  background: #FFF3F3;
}
.dropdown-menu-right > .list-group-item-action-main > i{
  float: none;
  color: #6583E8;
  font-size: 24px;
  margin-right: 10px;

}
.btn-primary{
  background: #6583E8 !important;
  border: 1px solid #6583E8 !important;
}
.form-group{
  margin-bottom: 0 !important;
}
.breadcrumsUl{
  width: 100%;
  display: inline-block
}
.breadcrumsUl li{
  display: inline-block;
  position: relative;
  margin-right: 20px;
}
.breadcrumsUl li:after{
  content: ">";
  display: inline-block;
  position: absolute;
  top: 0;
  right: -10px;
}
.breadcrumsUl li a{
  color: #00000080 !important;
  font-size: 16px;
  font-weight: 400;
  margin-right: 10px;
}
.resetButton{
  background: #0000001A;
  color: #000;
  font-size: 16px;
  margin-right: 10px;
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  position: absolute;
  right: 148px;
}
.resetButton span{
  font-weight: 600;
    font-size: 16px;
    margin-left: 8px;
}
/*=======main-menu======*/
.page-content-wrapper {
  background: #f9f9f9;
  position: relative;
  width: calc(100% - 240px) !important;
  float: right;
  /* height: 100vh;
  z-index: 9; */
}
.dashboard-middle-content {
  padding: 100px 50px 0;
  position: relative;
}
.dashboard-top-filter {
  margin-bottom: 20px;
}
.from-date,
.filter-label {
  display: block;
  color: #1a1a1a;
  font-weight: bold;
  font-size: 14px;
}
.dashboard-top-filter-input {
  width: 100%;
  display: inline-block;
  border: none;
  border: 1px solid #989898;
  padding: 4px 20px;
  /* padding-bottom: 10px; */
  border-radius: 5px;
  background: transparent;
}
.dashboard-top-filter-input:focus {
  outline: none;
}
.dashboard-top-filter2 {
  text-align: center;
}

.dashboard-filter-buttonblue-main {
  height: 30px;
  border-radius: 4px;
  background: #7d37de;
  text-align: center;
  color: #fff !important;
  font-size: 14px;
  border: none;
  margin-right: 20px;
  padding: 0 60px;
  margin-top: 35px;
}
.dashboard-filter-buttongrey-main {
  height: 30px;
  border-radius: 4px;
  background: #797979;
  text-align: center;
  color: #fff !important;
  font-size: 14px;
  border: none;
  padding: 0 60px;
  margin-right: 20px;
  margin-top: 35px;
}

.dashboard-filter-buttonblue {
  /* height: 30px; */
  height: 35%;
  width: 60%;
  border-radius: 4px;
  background: #7d37de;
  /* text-align: center; */
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff !important;
  font-size: 14px;
  border: none;
  margin-right: 20px;
  padding: 15px 10px;
  margin-top: 35px;
}
.dashboard-filter-buttongrey {
  height: 35%;
  width: 80%;
  border-radius: 4px;
  background: #797979;
  /* text-align: center; */
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff !important;
  font-size: 14px;
  border: none;
  padding: 15px 10px;
  margin-right: 20px;
  margin-top: 35px;
}

.dashboard-dashboardboxes-main {
  padding: 25px 0 0;
}
.services-heading {
  color: #1a1a1a;
  font-weight: bold;
  font-size: 16px;
  line-height: 1.42857;
  margin-bottom: 20px;
}
.dashboard-dashboardboxes {
  background: linear-gradient(45deg, #8f57dc 0%, #732cd0 100%);
  color: #fff !important;
  border-radius: 10px;
  padding: 15px 10px 25px;
  box-shadow: 0px 0px 10px rgb(153 153 153 / 40%);
}
.amount-trans {
  color: #fff;
  font-weight: 600;
  font-size: 30px;
  margin-bottom: 15px;
  padding: 0 8px;
}
.total-percentage-nmbrs {
  margin-top: 15px;
}
.total-percentage-nmbrs1 {
  width: 33.3%;
  float: left;
  text-align: center;
  border-right: 1px solid #fff;
}
.total-percentage-nmbrs1:nth-child(3) {
  border-right: 0;
}
/* .dec-date{} */
.dashboard-dashboardboxes-gradient {
  background: linear-gradient(45deg, #ffa3ca 0%, #fc5ea0 100%);
}
.dashboard-dashboardboxes-transparent {
  background: #fff;
  color: #fff;
  border-radius: 10px;
  padding: 15px 18px 25px;
  box-shadow: 0px 0px 10px rgb(153 153 153 / 40%);
  position: relative;
  min-height: 147.8px;
}
.no-of-transdashboard {
  display: block;
  color: #787878;
  font-size: 14px;
}
.nmbrs-amountdashboard {
  margin: 0;
  font-size: 25px;
  font-weight: 600;
  color: #111;
}
.services-icons {
  position: absolute;
  top: 15px;
  right: 15px;
}
.services-icons img {
  display: inline-block;
}

.filter-main {
  background: #fff;
  box-shadow: 0px 0px 15px rgb(0, 0, 0, 0.1);
  padding: 25px 32px 50px;
  border-radius: 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  margin-top: 40px;
}
.filter-main h4 {
  font-size: 22px;
}
.filter-div-padding {
  padding-left: 200px;
}
.pitb-footer {
  text-align: center;
  color: #00000080;
  font-size: 12px;
  position: relative;
  bottom: 0px;
  left: 0;
  width: 100%;
  display: inline-block;
  font-weight: 600;
  /* background: #ffffff; */
  padding: 30px 0px 0xp;
  z-index: 4;
}
.css-1nmdiq5-menu{
  z-index: 9 !important;
}
.css-1fdsijx-ValueContainer{
  padding-left: 35px !important;
}
.btn-active{
  background: #72CA97;
  color: #fff;
  font-size: 14px;
  width: 125px;
  height: 33px !important;
  line-height: 0 !important;
  display: inline-block;
  text-align: center;
  border-color: #72CA97;
  border-radius: 5px;
}
.btn-active:hover{
  background: #72CA97;
  color: #fff;
}
.btn-inactive{
  background: #0000000D;
  color: #111;
  font-size: 14px;
  width: 125px;
  height: 33px !important;
  line-height: 0 !important;
  display: inline-block;
  text-align: center;
  border-color: transparent;
  border-radius: 5px;
}
.btn-inactive:hover{
  background: #0000000D;
  color: #111;
}
.btn-edit{
  background: #fff;
  color: #111;
  font-size: 14px;
  width: 125px;
  height: 33px !important;
  line-height: 0 !important;
  display: inline-block;
  text-align: center;
  border-color: #0000004D;
  border-radius: 5px;
}
.btn-edit:hover{
  background: #fff;
  color: #111;
}
@media (min-width: 768px) {
  .sidebar-wrapper {
    margin-left: 0;
  }

  .page-content-wrapper {
    min-width: 0;
    width: 100%;
  }
  .wrapper.toggled .page-content-wrapper {
    width: 96% !important;
  }
  .wrapper.toggled .sidebar-wrapper {
    margin-left: -11rem;
    z-index: 970;
  }
  .wrapper.toggled .nav-bg {
    left: 65px;
    transition: 0.25s ease-out;
  }
}

.container-view {
  padding: 100px 15px;
  max-width: 1300px;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
/* ===== ---- Card ---- ===== */
.no___margin {
  margin: 0 0 !important;
}
#company-search{
  z-index: 9;
}
input[type="date"]{
  padding-left: 35px;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  left: 0px;
  color: #ccc !important;
  /* background: url(../images/filter_icons//uil_calender.png) no-repeat; */
  width: 410px;
  height: 35.5px;
  opacity: 0;
  cursor: pointer;
}
.w-100{
  position: relative;
}
input[type="date"]::before{
  color: #ccc;
  content: "";
  position: absolute;
  top: 4px;
  left: 10px;
  background: url(../images/filter_icons//uil_calender.png) no-repeat !important;
  width: 25px;
  height: 25px;
}
.position-absoluteIcon{
  position: absolute;
  top: 7px;
  left: 7px;
}
.paddingSelect{
  padding-left: 30px;
}
.heightBtn{
  height: auto !important;
  line-height: 1.5 !important;
}
@media (max-width: 992px) {
  /* .navbar-nav {
    float: right;
    width: 100%;
  } */
  .nav-item {
    float: left;
  }
  .nav-bg{
    padding: 20px 15px !important;
  }
  .navbar-nav .dropdown-menu{
    position: absolute;
  }
  .nav-tabs .nav-item{
    width: 50% !important;
  }
}
@media (max-width: 768px) {
  .page-content-wrapper {
    float: none;
    .dashboard-filter-buttonblue {
      /* height: 30px; */
      height: 40%;
      width: 30%;
    }
    .dashboard-filter-buttongrey {
      height: 40%;
      width: 40%;
    }
  }
  .nav-bg {
    left: 0;
  }
  .wrapper.toggled .dashboard-middle-content {
    width: calc(100% - 65px) !important;
    float: right;
  }
  .wrapper.toggled .sidebar-wrapper {
    margin-left: -11rem;
  }
  .wrapper.toggled .nav-bg {
    left: 65px;
  }
  .trapezoid{
    top: 17px;
    left: -10px;
  }
  .marginmobile{
    margin-top: 30px;
  }
}
.card-header {
  background-color: #56419f !important;
  color: #fff !important;
}
.table thead th {
  background: #b1aedd !important;
  color: #fff;
}
.table .vbt-table-tools th {
  background: #fff !important;
  color: #000;
}
.page-item.active .page-link {
  background-color: #b1aedd !important;
  border-color: #b1aedd !important;
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  background-color: #b1aedd !important;
  border-color: #b1aedd !important;
}

.dropdown-toggle::after {
  margin-top: -15px;
}

.dropdown-toggle > .newChanges {
  margin-top: -20px;
}

.dashboard-fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 980;
  padding: 0.3rem 1rem;
}

.transactionAmount {
  display: flex;
  justify-content: space-between;
}
.pitbCharges {
  margin-top: 5px;
}

@media (max-width: 992px) {
  .nav-responsive {
    position: absolute;
    top: 9px;
    right: 25px;
  }
}

.headerSelectionBox {
  display: flex;
  align-items: start;
}

@media (max-width: 768px) {
  .welcome-message {
    display: none;
  }
}

.welcome-message {
  color: black;
}

.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}
.SearchIcon{
  position: absolute;
  top: 10px;
  left: 10px;
}




/* PagenotFound */

/*======================
    404 page
=======================*/


.page_404{ padding:40px 0; background:#fff; font-family: 'Arvo', serif;
}

.page_404  img{ width:100%;}

.four_zero_four_bg{
 
 /* background-image: url(../images/images-landing/404-error-page.jpg); */
    /* height: 400px; */
    background-position: center;
    width: 100%;
    display: inline-block;
 }
 
 
 .four_zero_four_bg h1{
 font-size:80px;
 }
 
  .four_zero_four_bg h3{
			 font-size:80px;
			 }
			 
			 .link_404{			 
        background-color: #6583E8;
        outline: none;
        border: none;
        padding: 10px 20px;
        border-radius: 5px;
        color: white;
        font-size: 18px;
        font-weight: 600;
        width: 100%;
      }
      .link_404:hover{
        color: #fff;
        text-decoration: none;
      }
	.contant_box_404{ margin-top:40px;}

  /* Payment Inquiry */
  
.inquiryPayment {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 0px;
  background-color: #f9f9f9;
}

.inquiryPayment thead th {
  background-color: #eae8f3;
  color: #0f0f0f;
  font-weight: 700;
  font-size: 13px;
  border: none !important;
  /* text-align: center; */
  padding: 8px 10px 8px;
}
.inquiryPayment tbody tr {
  /* box-shadow: 1px 1px 1px 1px #e8e8e8; */
  background: #fff;
  border-bottom: 1px solid rgba(224, 226, 231, 1);
}
.inquiryPayment tbody tr td {
  border: none !important;
  /* text-align: center; */
  font-weight: 400;
  font-size: 13px;
  padding: 5.5px 10px 5.5px;
  color: #0a1728;
}

.inquiryPayment tr:hover {
  background-color: #ddd;
}


/* 
.tableFixHead {
  overflow: auto;
  height: 60vh;
}
.tableFixHead thead th {
  position: sticky;
  top: 0;
  z-index: 1;
} */

.nav-tabs .nav-item{
  padding: 3px 0;
    border-radius: 0 !important;
    width: 15.5%;
    display: inline-block;
    color: #111;
    text-align: center;
    text-decoration: none;
}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  color: #56419e !important;
  border: 0;
  border-bottom: 1px solid #56419e;
}
.nav-tabs .nav-link:hover {
  border: 0;
}
.nav-tabs {
  border-bottom: 1px solid transparent;
}
.ltdtButton {
  padding: 7px 40px;
  border-radius: 4px;
  background: #ffffff;
  color: #000000 !important;
  font-size: 14px;
  border: 1px solid #0000004D;
  margin-right: 10px;
  position: absolute;
  top: 1px;
  right: 100px;
}
.searchbar {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  background-color: #6f42c1;
  color: white;
  padding: 4px 8px;
  border-radius: 6px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1100;
}
.search-container {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
@media (max-width:992px){
  .iconSearch{
    position: relative;
    right: 180px;
  }
  .searchbar {
    left: -850%;
  }
}
.selected-dates{
  position: absolute;
  top: 2px;
  right: 200px;
  padding: 7px 40px;
    border-radius: 4px;
    /* background: #EFEFEF; */
    color: #000000 !important;
    font-size: 14px;
    margin-right: 10px;
    height: 36.6px;
}
.selected-dates-reports{
  position: absolute;
  top: 2px;
  right: 70px;
  padding: 7px 40px;
    border-radius: 4px;
    /* background: #EFEFEF; */
    color: #000000 !important;
    font-size: 14px;
    margin-right: 10px;
    height: 36.6px;
}
