table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 0px; 
  background-color: #f9f9f9;
  border-radius: 12px; 
  overflow: hidden; 
}

table tbody tr:first-child td:first-child {
  border-top-left-radius: 12px; 
}

table tbody tr:first-child td:last-child {
  border-top-right-radius: 12px;
}

table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 12px; 
}

table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 12px; 
}

table tr th {
  background-color: #eae8f3;
  color: #0f0f0f;
  font-weight: 700;
  font-size: 13px;
  border: none !important;
  padding: 8px 10px; 
  text-align: left; 
}

table tbody tr {
  background: #fff;
  border-bottom: 1px solid rgba(224, 226, 231, 1); 
}

table tr td {
  border: none !important;
  font-weight: 400;
  font-size: 13px;
  padding: 5.5px 10px;
  color: #0a1728;
}

table tr:hover {
  background-color: #f1f1f1; 
}

.scrollable {
  max-height: auto; 
  overflow-y: auto; 
}

.tableFixHead {
  overflow: auto;
  max-height: 60vh; 
}
.tableFixHead thead th {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #eae8f3; 
}

.table {
  margin-top: 8px;
}

.noDataMessage {
  text-align: center;
  padding: 16px;
  font-size: 14px;
  color: #7a7a7a;
}
