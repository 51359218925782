.parentNode {
  min-height: 90vh;
}
.sidebarDiv1 {
  display: flex;
  align-items: center;
  padding-left: 20px;
  
}
.sidebardiv2 {
  background-color: #7261ae;
  border-radius: 5px;
  padding: 2px 6px;
}

.profileIcon {
  background-color: pink;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.sidBarCloseIcons {
  display: flex;
  justify-content: space-between;
  padding: 20px 25px;
}

.nestedDiv {
  position: absolute;
  background-color: white;
  color: black;
  left: 400;
  display: flex;
  flex-direction: column;
  width: 220px;
  border-radius: 10px;
}

.nestedDiv1 {
  margin-left: 162px;
  margin-top: 40px;
  cursor: pointer;
}

.roleStyles {
  color: #4d4d4d;
  font-size: 12px;
  font-weight: 400;
}
.footerclass{
  font-family: 'Segoe UI', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    justify-content: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    background: #00000080;
}