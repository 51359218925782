.pagination {
  margin: 12px 0px 0;
  text-align: right;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.pageInfo {
  color: rgba(35, 35, 37, 0.54);
  font-size: 16px;
  font-weight: 400;
}

.hreSelect {
  cursor: pointer;
  padding: 4px 8px;
  margin-left: 4px;
  border: 1px solid rgba(86, 65, 158, 0.6);
  border-radius: 4px;
  background-color: rgba(86, 65, 158, 0.1);
  color: rgba(86, 65, 158, 1);
  transition: background-color 0.3s ease;
  outline: none !important;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.06);
}
.hreSelect:hover {
  background-color: rgba(86, 65, 158, 0.2);
}

.pagebuttons {
  display: flex;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.06);
  color: rgba(35, 35, 37, 1);
  padding: 4px;
}

.pageBtn {
  width: auto;
  height: 35px;
  padding: 0px 10px;
  font-weight: normal;
  font-size: 15px;
  border: none;
  background-color: transparent;
  &&:disabled {
    color: rgba(0, 0, 0, 0.14);
  }
}

.activeBtn {
  color: white;
  background-color: rgba(86, 65, 158, 1);
  border-radius: 3px;
}

.disabledPageBtn {
  /* background-color: #a0a3bd; */
  cursor: not-allowed;
  opacity: 0.5;
}
