/* Filter Box Styles */
.filterBoxHidden {
  position: fixed;
  right: -100%;
  top: 6%;
  width: 450px;
  transition: right 0.5s ease-in-out;
}
.passwordInputgroup {
  position: relative;
}

.togglePassword {
  position: absolute;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.togglePasswordInvalid {
  position: absolute;
  right: 40px;
  top: 30%;
  transform: translateY(-50%);
  cursor: pointer;
}
.filterBoxVisible {
  animation: slideInFromRight 0.5s ease-in-out forwards;
   position: fixed;
  right: 0;
  top: 6%;
  width: 450px;
  height: 100%;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  padding: 0px;
  margin-top: -40px;
  box-sizing: border-box;
  z-index: 1000;
  border-radius: 6px;
  overflow-y: scroll;
  transition: right 0.5s ease-in-out;
}

.filterButtonText {
  font-weight: 600;
  font-size: 16px;
  margin-left: 8px;
}

.filterButton, .heightBtn {
  display: inline-block;
  z-index: 1001; 
  position: relative;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

/* Hover Effect */
.filterButton:hover, .heightBtn:hover {
  background-color: #4e6de8; 
  transform: scale(1.05); 
  cursor: pointer;
}

/* Optional: Focus Effect */
.filterButton:focus, .heightBtn:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(100, 100, 255, 0.5);
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(119, 119, 119, 0.4);
  z-index: 999;
  display: none; 
}


.overlay.visible {
  display: block;
}
@keyframes slideInFromRight {
  from {
    right: -100%;
  }
  to {
    right: 0;
  }
}
@media (max-width: 768px) {
  .filterBoxVisible {
    width: 330px;
    top: 5%; 
  }

  .overlay.visible {
    background: rgba(119, 119, 119, 0.7); 
  }
}
.consumerNumber1 {
  background: transparent;
  border-right-width: 0;
  outline: none;
}

.dashboardBox1 {
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 13px rgba(0,0,0,.08);
}

.dashboardBox2 {
  font-size: 18px;

  color: #00000080;
  font-weight: 600;
}

.dashboardServiceBox1 {
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  /* min-height: 272px; */
  box-shadow: 0 0 13px rgba(0,0,0,.08);
}

.dashboardServiceBox2 {

  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #00000033;
  padding-bottom: 10px;
}
.btn-primary{
  background: #6583E8 !important;
  border: 1px solid #6583E8 !important;
}

@media (max-width:768px){
  .filterBoxVisible{
    width: 330px;
  }
}

/* .dashboardServiceBox3 {
  display: flex;
  gap: 12px;
  align-items: center;
} */

